body {
  background-color: black;
}

.vertical-center {
  min-height: 100%;
  display: flex;
  align-items: center;
}

.App {

}
