.blast-root h1 {
  font-family: 'Press Start 2P', cursive;
  font-size: 25px;
  color: chartreuse;
  padding-left: 20px;

  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: .8em solid chartreuse; /* The typewriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  letter-spacing: -0.05em; /* Adjust as needed */

  animation:
      typing 3.5s steps(18, end),
      blink-caret .50s step-end infinite;
}

@media (min-width:1000px) {
  .blast-root h1 {
    font-size: 40px;
  }
}

@media (min-width:600px) {
  .blast-root h1 {
    font-size: 30px;
  }
}

/* The typing effect */
@keyframes typing {
  from { width: 0 }
  to { width: 99% }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: chartreuse; }
}
