@media (min-width: 700px) {
    .about-me.card {
        height: 40%;
        margin-top: 7%;
    }
}

.card-header {
    font-family: 'Gemunu Libre', sans-serif;
}

.card-footer {
    font-family: 'Gemunu Libre', sans-serif;
}

a.contact-me {
    font-family: 'Gemunu Libre', sans-serif;
}

.about-me.card {
    background: #000000;
    color: #17b06b;
    border-color: #17b06b;
    padding: 0;
}

.card-header {
    background: #193f1b;
}

.card-footer {
    background: #193f1b;
}

.resume-summary > p {
    font-family: 'Gemunu Libre', sans-serif;
    font-size: larger;
}
